'use strict';

angular.module('enervexSalesappApp').controller('FuelsCtrl', function($scope, Fuel, _) {
	$scope.allFuels = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		Fuel.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(fuels){
			if (fuels.length > 0) {
				$scope.allFuels = $scope.allFuels.concat(fuels);
				$scope.offset = $scope.offset + $scope.limit;
				$scope.allFuels = _.sortBy($scope.allFuels, function(fuel){
					return fuel.sortOrder * 1
				})
				fetch();
			} else {
				$scope.fuels = $scope.allFuels
			}
		});
	}
	fetch();
	$scope.delete = function(fuel) {
		Fuel.delete({
			id: fuel._id
		});
		$scope.allFuels = _.filter($scope.allFuels, function(_f){
			return _f._id != fuel._id
		})
		$scope.fuels = $scope.allFuels
		// $scope.applyFilter()
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fuels = _.filter($scope.allFuels, function(rule){
	// 		if (nameReg && !nameReg.test(rule.code) ) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'BWH' && !rule.BWH) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'COM' && !rule.COM) {
	// 			return false;
	// 		} else if ($scope.computeAs== 'DRY' && !rule.DRY) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
